<template>
    <section class="w-full object-cover bg-center bg-cover bg-no-repeat bg-atuacao-bg pb-24 lg:pb-96 md:mt- lg:pt-0">
        <h2 class="font-secondary text-white pl-4 pr-4 text-5xl md:px-8 lg:text-7xl font-bold md:mb-8 lg:mb-8 mt-4 pt-32 lg:pt-64 w-full max-w-[1280px] mx-auto">Atuação</h2>
        <ul class="grid grid-cols-2 md:grid-cols-4 md:p-8 lg:grid-cols-5 gap-8 text-center max-w-[1280px] mx-auto pl-4 pr-4">
            <li class="filter drop-shadow-md 
            space-y-4 
            relative 
            flex flex-col 
            mx-auto bg-cb-light 
            lg:w-52 lg:h-52 
            w-40 h-40
            rounded-2xl 
            backdrop-blur-md bg-white/30 mb-0" v-for="(item,index) in items" :key="index">
                <span class="absolute bottom-4 left-4 w-32 text-lg font-secondary text-cb-dark font-bold text-left">{{item.title}}</span>
            </li>
        </ul>
    </section>
</template>

<script>
export default {
    name: 'AtuacaoSection',
    data() {
        return {
            items: [
                { title: 'Crimes contra a honra'},
                { title: 'Crimes contra o patrimônio'},
                { title: 'Crimes contra a propriedade intelectual'},
                { title: 'Crimes contra a dignidade sexual'},
                { title: 'Crimes contra a paz pública'},
                { title: 'Crimes contra a fé pública'},
                { title: 'Crimes contra a administração pública'},
                { title: 'Lei antidrogas'},
                { title: 'Lei de preconceitos'},
                { title: 'Lei anticorrupção'},
                { title: 'Crimes contra o consumidor'},
                { title: 'Crimes ambientais'},
                { title: 'Crimes contra a criança e o adolescente'},
                { title: 'Crimes de trânsito'},
                { title: 'Lei Maria da Penha'},
            ]
        }
    }
}
</script>