<template >
        <div class="relative shadow bg-white hidden md:block z-10">
            <div class="w-full bg-cb-light filter drop-shadow-lg bg-opacity-70 fixed"
            :class="{'translate-y-0': showNavbar, '-translate-y-full': !showNavbar, 'transition-transform duration-500 ease-in-out': true}"
            >
                <div class="h-16 mx-auto px-5 max-w-7xl flex items-center justify-center ">
                    <ul class="flex items-center gap-5 font-secondary text-cb-primary">
                        <li><a class="hover:text-cb-light transition-colors scroll-link" href="#escritorio">O escritório</a></li>
                        <li><a class="hover:text-cb-light transition-colors scroll-link" href="#socia">A sócia</a></li>
                        <li><a class="hover:text-cb-light transition-colors scroll-link" href="#atuacao">Atuação</a></li>
                        <li><a class="hover:text-cb-light transition-colors scroll-link" href="#contato">Entre em contato</a></li>
                    </ul>
                </div>
            </div>
        </div>
    
    <div class="block fixed bottom-4 right-4 w-12 h-12 md:bottom-16 md:right-16 md:w-16 md:h-16 rounded-[64px]" 
    :class="{'opacity-0': showNavbar, 'opacity-100': !showNavbar, 'transition-opacity duration-500 ease-in-out': true}">
        <a href="#inicio" class="scroll-link">
            <img src="@/assets/up-scroll.svg" alt="">
        </a>
    </div>
    <HeaderSection id="inicio" class="scroll-smooth"/>
    <EscritorioSection id="escritorio"/>
    <SociaSection id="socia"/>
    <AtuacaoSection id="atuacao"/>
    <ContatoSection id="contato"/>
    <FooterSection/>
</template>

<script>
import HeaderSection from './header.vue';
import EscritorioSection from './escritorio.vue';
import SociaSection from './socia.vue';
import AtuacaoSection from './atuacao.vue';
import FooterSection from './footer.vue';
import ContatoSection from './contato.vue';

export default {
    name: "HomePage",
    components: { 
        EscritorioSection, 
        AtuacaoSection,
        HeaderSection,
        FooterSection,
        SociaSection,
        ContatoSection
    },
    mounted() {
        document.querySelectorAll('.scroll-link').forEach(anchor =>{
            anchor.addEventListener('click', function (e) {
                e.preventDefault();
                document.querySelector(this.getAttribute('href')).scrollIntoView({
                    behavior: 'smooth'
                });
            });
        });
    },
    data(){
        return{
            showNavbar:true,
        }
    },
    created(){
        window.addEventListener('scroll', this.handleScroll);
    },
    unmounted(){
        window.removeEventListener('scroll', this.handleScroll);
    },
    methods:{
        handleScroll(){
            this.showNavbar = window.scrollY < 80;
        }
    }
}
</script>
