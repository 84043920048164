<template>
    <section class="px-8">
        <h2 class="font-secondary text-5xl md:text-6xl mb-8 pt-32 text-cb-primary w-full text-center">O Escritório</h2>
        <div class="grid md:grid-cols-3 gap-24 md:gap-8 sm:p-12 md:p-4 max-w-[1280px] mx-auto">
            <div class="text-center w-full escritorio1">
                <img src="@/assets/especializacao.png" alt="" class="max-w-[160px] mx-auto">
                <span class="text-3xl font-secondary text-cb-primary">Especialização</span>
                <hr class="text-cb-primary w-32 mx-auto mt-6">
                <p class="font-secondary text-cb-primary mb-8 mt-6">Foco em consultoria e advocacia criminal, resolução de conflitos e suporte em compliance.</p>
                <p class="font-secondary text-cb-primary mb-8">Experiência robusta na intermediação de conflitos de interesses e no apoio a casos relacionados à lei criminal, desde prevenção até ação direta contra fraudes e pirataria.</p>
                <p class="font-secondary text-cb-primary mb-8">Atendimento dedicado tanto para defesa quanto para acusação, garantindo um suporte abrangente.</p>
            </div>
            <div class="text-center w-full escritorio2">
                <img src="@/assets/trajetoria.png" alt="" class="max-w-[160px] mx-auto">
                <span class="text-3xl font-secondary text-cb-primary">Trajetória</span>
                <hr class="text-cb-primary w-32 mx-auto mt-6">
                <p class="font-secondary text-cb-primary mb-8 mt-6">Início de carreira em um dos mais renomados escritórios de direito criminal no Brasil.</p>
                <p class="font-secondary text-cb-primary mb-8">Ampla experiência em direito corporativo, atuando como advogada in-house para uma grande empresa de telecomunicações e contribuindo como consultora externa em propriedade industrial e regulamentação.</p>
            </div>
            <div class="text-center w-full escritorio3">
                <img src="@/assets/atendimento.png" alt="" class="max-w-[160px] mx-auto">
                <span class="text-3xl font-secondary text-cb-primary">Atendimento</span>
                <hr class="text-cb-primary w-32 mx-auto mt-6">
                <p class="font-secondary text-cb-primary mb-8 mt-6">Compromisso com a transparência, ética e confidencialidade em todos os processos.</p>
                <p class="font-secondary text-cb-primary mb-8">Profissionais altamente especializados oferecendo serviços personalizados em São Paulo e Rio de Janeiro, com uma ampla rede de correspondentes pelo Brasil.</p>
                <p class="font-secondary text-cb-primary mb-8">A abordagem do escritório valoriza o atendimento individualizado, visando a construção de uma relação de confiança e parceria duradoura com cada cliente.</p>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    name: 'EscritorioSection'
}
</script>