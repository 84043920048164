<template>
    <section class="w-full">
        <div class="max-w-[1246px] mx-auto mt-16 p-8 md:p-16 md:pb-40 lg:pt-32 lg:pb-64">
            <h1 class="text-5xl sm:text-6xl font-secondary text-cb-primary">Entre em<br/>contato</h1>
            <p class="py-8 max-w-[800px] font-secondary text-cb-primary text-base sm:text-lg font-normal">Precisa conversar sobre um caso? Quer entender melhor suas possibilidades e direitos? Precisa de suporte jurídico? Entre em contato!
            </p>
            <form action="your_server_endpoint" method="post" class="max-w-[480px]">
                <div class="mb-4">
                    <input type="email" name="email" placeholder="Seu email"
                        class="w-full py-2 px-4 bg-cb-light text-cb-primary font-secondary rounded-md focus:ring focus:ring-blue-500 focus:outline-none">
                </div>

                <div class="mb-4">
                    <textarea name="message" placeholder="Conte-nos o motivo do seu contato" rows="4"
                        class="w-full py-2 px-4 bg-cb-light text-cb-primary font-secondary rounded-md focus:ring focus:ring-blue-500 focus:outline-none"></textarea>
                </div>

                <button type="submit"
                    class="py-2 px-6 bg-cb-primary text-white rounded-md hover:bg-red-500 focus:ring focus:ring-red-500 focus:outline-none">
                    Enviar
                </button>
            </form>
        </div>
    </section>
</template>

<script>
export default {
    name: 'ContatoSection'
}
</script>