<template>
    <section class="flex items-center w-full object-cover bg-center bg-cover bg-no-repeat h-screen bg-hero-bg">
        <img src="@/assets/logo-hero.svg" alt="" class="mx-auto mb-32 w-80 lg:w-1/4">
        <a href="#escritorio" class="absolute bottom-12 left-1/2 -translate-x-1/2 scroll-link">
            <img src="@/assets/start-navigation.svg" alt="" class="">
        </a>
    </section>
</template>

<script>
export default {
    name: 'HeaderSection'
}
</script>