<template>
        <section class="w-full block bg-cb-primary bg-gradient-to-b from-cb-primary  to-amber-950 pt-24 md:pt-12 bg-cover bg-center bg-no-repeat text-white">
        <!-- Footer Container -->
        <div class="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-12 px-16 max-w-[1246px] mx-auto py-10">
            <!-- Logo and Services Section -->
            <div class="flex flex-col">
                <!-- Logo -->
                <div class="mb-4 md:mb-0 mx-auto">
                    <img class="fill-slate-700 text-white" src="@/assets/logo-horizontal-light.svg" alt="">
                </div>
            </div>

            <!-- Services List -->
            
            <div class="flex flex-col text-left">
                <div class="flex flex-row border-l-[1px] pl-8 border-cb-primary border-collapse border-">                    
                    <div>
                        <ul class="list-none text-xl font-secondary mt-4">
                            <li class="mb-4">O escritório</li>
                            <li class="mb-4">A sócia</li>
                            <li class="mb-4">Atuação</li>
                            <li>Entre em contato</li>
                        </ul>
                    </div>
                </div>
            </div>
        
            <!-- Contact -->
            <div class="flex flex-col text-left">
                <!-- Contact Section -->
                <div class="flex flex-row border-l-[1px] pl-8 border-cb-primary border-collapse">
                    <div class="mb-4 md:mb-0">
                        <h2 class="text-2xl mt-3 mb-4 font-normal font-secondary">Alguma dúvida?</h2>
                        <p class="mb-1">Entre em contato com <a href="mailto:info@labsecreto.com"
                                class="underline">camila@camilabouza.com</a>.</p>
                        <p>Em caso de emergências, dúvidas informações.</p>
                    </div>
                </div>
                
            </div>
        </div>
        <!-- Social Media Links (Replace with actual links) -->
        <h2 class="font-secondary text-xl w-full text-center mt-16">Siga-nos nas redes</h2>
        <div class="w-full flex items-center gap-4 text-center mx-auto mb-24">
            <div class="max-w-[400px] mx-auto items-center flex gap-8 mt-4">
                <a href="#" class="flex-none block w-8 h-8">
                    <img src="@/assets/instagram.svg" alt="">
                </a>
                <a href="#" class="flex-none block w-8 h-8">
                    <img src="@/assets/youtube.svg" alt="">
                </a>
                <a href="#" class="flex-none block w-8 h-8">
                    <img src="@/assets/twitter.svg" alt="">
                </a>
            </div>
        </div>
        <div class="mt-6 text-sm text-center pb-4 font-secondary">
            Copyright 2023 © Todos os direitos reservados. Desenvolvido por CB Tech.
        </div>

    </section>
</template>

<script>
export default {
    name: 'FooterSection'
}
</script>