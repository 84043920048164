<template>
    <section class="grid px-12 md:grid-cols-2 md:gap-16 gap-4 md:p-16 mt-24 text-cb-primary max-w-[1280px] mx-auto">
        <div class="flex flex-col order-2 md:order-1">
            <span class="font-black font-secondary mt-8">Sócia proprietária</span>
            <h2 class="font-secondary text-7xl md:text-8xl mb-8 mt-4">Camila<br/> Bouza</h2>
            <div class="max-w-[520px]">
                <p class="text-base font-secondary mb-8">
                    Advogada graduada em 2005, especialista em direito penal econômico e compliance pela FGV e pós-graduada em direito civil, empresarial e processo civil pela UVA/Fesudeperj.
                </p>
                <p class="text-base font-secondary mb-8">
                    Iniciou sua formação profissional no escritório de direito criminal Nelio Machado Advogados e em 2011 ingressou na gerência jurídica criminal da Oi/Telemar como advogada especialista in house. Depois de quase 7 anos, deixou empresa para inaugurar a área criminal do Licks Attorneys, onde permaneceu por mais 6 anos. Desde maio de 2021, abriu seu escritório para atender clientes na prevenção e resolução de conflitos no âmbito criminal.
                </p>
                <p class="text-base font-secondary mb-8">
                    Entre suas principais atividades dedicadas a clientes corporativos estão: a avaliação de riscos e estratégias empresariais, riscos na continuidade dos negócios, prevenção e combate às fraudes internas e externas, análise de projetos de lei, combate à pirataria, assuntos relacionados à quebra de sigilo, programas de proteção de dados e projetos de adequação à LGPD e à GDPR, auxílio na elaboração de estratégias de diversidade e inclusão. Para pessoas físicas, auxílio na defesa de clientes acusados ou vítimas de crimes comuns, crimes cibernéticos, crimes contra a honra, contra a família e dignidade sexual.
                </p>
            </div>
        </div>
        <div class="flex flex-col items-center text-center order-1 md:order-2">
            <img src="@/assets/perfil02.jpg" alt="" class="mt-16">
        </div>
    </section>
</template>

<script>
export default {
    name: 'SociaSection'
}
</script>